export default class Cookie {
    constructor(section, path, expires) {
        this.section = section
        this.path = path
        this.expires = expires
    }

    /**
     * Gets the current value of a key
     *
     * @param {string} key
     * @returns {*}
     */
    get(key) {
        const cookie = Cookies.getJSON(this.section)

        if (typeof cookie === 'object') {
            return cookie[key] || null
        }

        return null
    }

    /**
     * Sets the value of a key
     *
     * @param {string} key
     * @param {*} [value] - Passing no value will delete the key:value pair
     */
    set(key, value) {
        let cookie = Cookies.getJSON(this.section),
            options = {
                path: this.path
            }

        if (typeof cookie !== 'object') {
            cookie = {}
        }

        if (this.expires) {
            _assign(options, { expires: this.expires })
        }

        cookie[key] = value
        Cookies.set(this.section, cookie, options)
    }

    /**
     * Clears all values for the section
     */
    clear() {
        Cookies.remove(this.section, { path: this.path })
    }
}
