/**
 * Tools
 */
import Cookie from './cookie.js'

class Tools {
    /**
     * Gets global cookie
     *
     * @param {string} section
     * @returns {Cookie}
     */
    static getCookie(section) {
        return new Cookie(section, '/')
    }

    /**
     * Gets local cookie
     *
     * @param section
     * @param {number} expires
     * @returns {Cookie}
     */
    static getCookiePath(section, expires = 30) {
        return new Cookie(section, window.location.pathname, expires)
    }

    /**
     * Polyfills the event.path js object if needed
     *
     * @param {object} event
     * @returns {array}
     */
    static getPath(event) {
        let element = event.target || null,
            path = [element]
        if (!element || !element.parentElement) {
            return []
        }
        while (element.parentElement) {
            element = element.parentElement
            path.push(element)
        }
        path.push(document)
        path.push(Window)
        return path
    }
}

window.Tools = Tools
